/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lozad@1.16.0/dist/lozad.min.js
 * - /npm/gsap@3.7.1/dist/gsap.min.js
 * - /npm/scrollmagic@2.0.8/scrollmagic/uncompressed/ScrollMagic.min.js
 * - /npm/scrollmagic@2.0.8/scrollmagic/uncompressed/plugins/animation.gsap.js
 * - /npm/scrollmagic@2.0.8/scrollmagic/uncompressed/plugins/debug.addIndicators.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
